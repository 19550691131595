<template>
  <button
      type="button"
      class="o-unstyled-btn  c-theme-toggle  u-absolute  u-br-5  u-bg-contrast  u-cursor-pointer"
      :aria-label="ariaLabel"
      @click="toggleTheme">

    <SvgSprite
        :icon="theme == 'dark' ? 'light-mode' : 'dark-mode'"
        aria-hidden="true"
        width="12"
        height="12"
        class="u-fill-secondary">
    </SvgSprite>

  </button>
</template>

<script setup lang="ts">
    import { computed, onMounted, ref, watch } from 'vue';
    import SvgSprite from '@components/SvgSprite.vue'
    import { DARK, LIGHT } from '@data/themes';
    import getLocalStorageItem from '@utility/storage/get-local-storage-item';
    import setLocalStorageItem from '@utility/storage/set-local-storage-item';

    const LOCAL_STORAGE_KEY = 'theme';
    const theme = ref('');
    const storageMode = getLocalStorageItem(LOCAL_STORAGE_KEY);
    const isSystemDarkMode = matchMedia && matchMedia('(prefers-color-scheme: dark)').matches;

    // Set the aria-label value depending on the current theme
    const ariaLabel = computed(() => {
        return theme.value == DARK
            ? 'Toggle light mode'
            : 'Toggle dark mode'
    });

    onMounted(() => {
        getCurrentTheme();
    });

    // Once the component is mounted determine the current theme
    function getCurrentTheme () {

        // If a theme value is set in local storage use that
        if (storageMode) {
            theme.value = storageMode;
            return;
        }

        // If system ui is set to "dark" use that
        if (isSystemDarkMode) {
            theme.value = DARK;
            return;
        }

        // Fallback to the light theme
        theme.value = LIGHT;
    };

    watch(() => theme.value, (theme) => {
        setTheme(theme);
    }, { immediate : true });

    function setTheme (theme: string) {
        (theme === DARK)
            ? updateThemeAttribute(DARK)
            : updateThemeAttribute(LIGHT);
    };

    /**
     *
     * @param currentTheme
     */
    function updateThemeAttribute (currentTheme: string) {
        document.documentElement.setAttribute('data-theme', currentTheme);
    };

    function toggleTheme () {
        (theme.value == DARK)
            ? theme.value = LIGHT
            : theme.value = DARK;

        updateThemeAttribute(theme.value);

        setLocalStorageItem(LOCAL_STORAGE_KEY, theme.value);
    };
</script>
